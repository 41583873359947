import { Component, OnInit } from '@angular/core';
import { DpAdminService } from '../services/dpAdmin/dp-admin.service';
import {environment} from '../../environments/environment';

@Component({
  templateUrl: 'home.component.html', 
  styleUrls: ['../app.component.scss', 'home.component.scss']
})

export class HomeComponent implements OnInit {
	isAdmin: boolean;
	isAnchorios: boolean;
	isAnchorandroid: boolean;
	constructor(private dpAdminService: DpAdminService) {}

	ngOnInit() {
	this.dpAdminService.checkAdminStatus();
	DpAdminService.isAdmin.subscribe((data: boolean) => { this.isAdmin = data; });
	DpAdminService.isAnchorios.subscribe((data: boolean) => { this.isAnchorios = data; });
	DpAdminService.isAnchorandroid.subscribe((data: boolean) => { this.isAnchorandroid = data; });
	}
}
