import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth-service/auth.service';
import {HttpClient} from '@angular/common/http';
import {DpAdminService} from '../services/dpAdmin/dp-admin.service';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
	selector: 'header-footer',
	templateUrl: './header-footer.component.html',
	styleUrls: ['./header-footer.component.scss']
})
export class HeaderFooterComponent implements OnInit {
	route: string;
	currentUser: string;
	version: string;
	loginID: string;
	isAdmin: boolean;
	isAnchorios: boolean;
	isAnchorandroid: boolean;
	locationViewMaxFlight: string;
	locationIosOneOff: string;
	locationDpTrend: string;
	locationAlphaBetaTrend: string;
	locationAndroidOneOff: string;

	constructor(
		private location: Location,
		private http: HttpClient,
		private dpAdminService: DpAdminService,
		private router: Router) {
			router.events.subscribe(val => {
				if (location.path() !== '') {
					this.route = location.path();
				} else {
					this.route = 'Home';
				}
			});
	}

	ngOnInit() {
	this.version = environment.version;
	AuthService.loginID.subscribe(data => {this.loginID = data});
	this.dpAdminService.checkAdminStatus();
	
	// Piggyback on async DpAdminSubscribe to have retrieveCDSID wait for session storage to populate
  	DpAdminService.isAdmin.subscribe((data: boolean) => { this.isAdmin = data;});
	DpAdminService.isAnchorios.subscribe((data: boolean) => { this.isAnchorios = data; });
	DpAdminService.isAnchorandroid.subscribe((data: boolean) => { this.isAnchorandroid = data; });
	}

	ngDoCheck() {
		this.pageSelected();
	}

	unsupportedBrowser(): boolean {
		return this.router.url === '/browser-not-supported';
	}

  isHome(): boolean {
    if ((this.route == "") || (this.route == "Home")) {
      return true;
    } else {
      return false;
    }
	}

	pageSelected(): void {
		if (this.route === '/ios-one-off-service') {
			this.locationIosOneOff = 'underline';
			this.locationDpTrend = "";
			this.locationAlphaBetaTrend="";
			this.locationAndroidOneOff = '';
		} else if (this.route === '/health-dashboard') {
			this.locationIosOneOff = '';
			this.locationDpTrend = '';
			this.locationAlphaBetaTrend="";
			this.locationAndroidOneOff = '';
		} else if (this.route === '/dp-trend') {
			this.locationDpTrend = 'underline';
			this.locationIosOneOff = '';
			this.locationAlphaBetaTrend="";
			this.locationAndroidOneOff = '';
		} else if (this.route === '/android-one-off-service') {
			this.locationAndroidOneOff = 'underline';
			this.locationDpTrend = '';
			this.locationAlphaBetaTrend="";
			this.locationIosOneOff = '';
		} else if (this.route == '/alpha-beta-failure-trend') {
			this.locationDpTrend = "";
			this.locationIosOneOff = "";
			this.locationAlphaBetaTrend="underline";
		}
	}
}
